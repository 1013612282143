.video-container {
    position: relative;
    overflow: hidden;
    height: 350px;
    @media only screen and (max-width: 630px) {
        height: auto;
    }
    .video {
        width: 100%;
        height: auto;
    }
    .header-container {
        position: absolute;
        top: 0;
        background-color: rgba(0, 0, 0, 0.7);
        height: 100%;
        display: flex;
        align-items: center;
        .header-inner-contents {
            @media only screen and (max-width: 500px) {
                h2 {
                    font-size: 1.2rem;
                }
                p {
                    font-size: 0.9rem;
                }
            }
        }
    }
}
@media only screen and (max-width: $medium) {
    .header-container {
        padding: 1rem 0 1.5rem !important;
        .chakra-container > div {
            display: flex;
        }
    }
}

.header-container {
    background-position: right top;
    background-image: url(../../assets/img/header.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    background-color: $yellow;
    position: relative;
    z-index: 0;
    @media only screen and (max-width: 900px) and (min-width: $medium) {
        background-position: 130% 100%;
    }
    @media only screen and (max-width: 800px) {
        background-position: 150% 100%;
        .header-bottom-container {
            width: 50%;
        }
    }
    @media only screen and (max-width: 630px) {
        &::before {
            content: "";
            width: 100%;
            height: 100%;
            background-color: $yellow;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
        .header-bottom-container {
            width: 100%;
        }
    }
    .header-inner-contents {
        .logo {
            @media only screen and (max-width: $small) {
                margin-bottom: 1rem;
                img {
                    margin: auto;
                }
            }
        }
    }
}
